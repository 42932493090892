@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/MaterialIcons.woff2') format('woff2')
}

@font-face {
    font-family: 'Noto Sans TC';
    src: url('fonts/NotoSansTC-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('fonts/roboto-regular-webfont.woff') format('woff');
    font-style: normal;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.verse-highlight {
    background: #aacbff;
    border: 1px solid #000;
    margin: 20px 0 10px !important;
    width: 100%;
}

.react-pdf__Document {
    margin: 0 auto;
    padding: 15px;
}
.react-pdf__Page {
    /*margin-top: -95px;
    margin-bottom: -95px;*/
}
.pdf-container {
    margin-right: auto;
}
.pdf-container canvas {
    margin-left: 10%;
}

.mobile-book-chapter-grid {
    flex-basis: unset !important;
}

.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: pointer;
}

u {
    text-decoration: none;
    border-bottom: 1px solid;
}

u.dotted {
    border-bottom: 2px dotted #000;
    text-decoration: none;
}

@media screen and (max-width: 700px) {
    .pdf-container canvas {
        margin-left: 25px;
    }
}
@media screen and (max-width: 400px) {
    .pdf-container canvas {
        margin-left: 0;
    }
}