#toc_container {
  background: #f9f9f9 none repeat scroll 0 0;
  border: 1px solid #aaa;
  display: table;
  font-size: 95%;
  margin-bottom: 1em;
  padding: 20px;
  min-width: 300px;
  width: 80%;
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
}

.toc_title {
  font-weight: 700;
  text-align: center;
  font-size: 24px;
}

#toc_container li, #toc_container ul, #toc_container ul li{
  list-style: outside none none !important;
  margin-bottom: 10px;
  font-size: 16px;
}