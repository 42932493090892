.page-container {
    .back-arrow {
      height: 0;

      img {
        height: 45px;
        margin-top: 10px;
        margin-left: 10px;
        cursor: pointer;
      }

      @media screen and (max-width: 779px) {
        height: 30px;

        img {
          height: 30px;
        }
      }
    }
}

.pdf-container {
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 779px) {
    margin-top: 20px;
  }
}