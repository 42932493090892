//
// Burger menu custom styles
//
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 10px;
  top: 18px;

  button {
    outline: 1px solid #fff;
    outline-offset: 8px;
  }
}

// Outline for burger button focus state
.bm-burger-button button:focus {
  outline: 0;
  outline-offset: 0;
}

// Background color for burger bars focus state
.bm-burger-button {
  button:focus + span {
    span.bm-burger-bars {
      background-color: #fff;
    }
  }
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #fff;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu-wrap {
  top: 64px !important;
  left: 0 !important;
  width: 270px !important;
}
.bm-menu {
  background: #f9f9f9;
  opacity: 0.9;

  a {
    color: #1401f0;
    font-size: 16px;
    text-align: center;

    &:hover,
    &:focus {
      color: #000;
    }
  }
}

.bm-item-list a {
  padding: 0.8em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}

.bm-item:focus {
  outline: none;
}

#slide {
  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
}

.bm-overlay {
  background: unset !important;
}